import { tns } from 'tiny-slider/src/tiny-slider';
const $sliders = document.querySelectorAll('.actualite-slider ul');
let $is_desktop = true;
if (window.innerWidth < 1024) {
    $is_desktop = false;
}
var mql_test = window.matchMedia('(min-width: 1024px)');

mql_test.onchange = (e) => {
    if (e.matches) {
        $is_desktop = true;
    }
    else {
        $is_desktop = false;
    }
}
(function ($) {
    $(document).ready(function () {
        if($is_desktop) {
            if($sliders){
                $sliders.forEach(function ($slider) {
                    var $parent = $slider.closest('.tns-outer')
                    var $id = $slider.getAttribute('id');
                    var slider = tns({
                        container: '#' + $id,
                        // autoWidth: true,
                        loop: false,
                        items: 3,
                        center: false,
                        mouseDrag: true,
                        slideBy: 1,
                        swipeAngle: false,
                        speed: 400,
                        autoplay: false,
                        controlsText: ['<svg class="icon icon-previous"><use xlink:href="#icon-previous"></use></svg>', '<svg class="icon icon-next"><use xlink:href="#icon-next"></use></svg>'],
                        controlsPosition: "bottom",
                        navPosition: "bottom",
                        onInit: function (info) {
                            info.controlsContainer.setAttribute('tabindex', -1);
                            info.nextButton.setAttribute('tabindex', 0);
                            info.prevButton.setAttribute('tabindex', 0);
                        },
                        responsive: {
                            250: {
                                items: 1,
                                gutter: 20,
                            },
                            992: {
                                items: 2,
                                gutter: 40,
                            },
                            1300: {
                                items: 3,
                                gutter: 50,
                            },
                            1440: {
                                items: 3,
                                gutter: 82,
                                edgePadding: 48,
                            }
                        },
                    });
                    slider.events.on('indexChanged', function (info, eventName) {
                        remove_aria_hidden_actualite_slider();
    
                    });
                });
            }
            remove_aria_hidden_actualite_slider();
        }
    });
     function remove_aria_hidden_actualite_slider() {
        const $tns_nav = document.querySelectorAll(".actualite-slider .tns-controls button");

        if ($tns_nav) {

            $tns_nav.forEach(function ($nav) {
                var value = $nav.getAttribute('data-controls');
            
                if(document.documentElement.lang =='fr-CA'){
                    if(value == 'next'){
                        $nav.setAttribute('aria-label', 'Suivant');
                        $nav.setAttribute('title', 'Suivant');
                    }else{
                        $nav.setAttribute('aria-label', 'Précédent');
                        $nav.setAttribute('title', 'Précédent');
                    }
                
                }else{
                    $nav.setAttribute('aria-label', value);
                    $nav.setAttribute('title', value);
                }
            });
        }
        const $tns_hidden = document.querySelectorAll(".actualite-slider .tns-item");

        if ($tns_hidden) {

            $tns_hidden.forEach(function ($item) {
/* on ne peut pas faire ca, tiny se sert de ca pour mettre la classe active sur la bonne slide */
                // $item.removeAttribute('aria-hidden');
            });
        }
    }
})(jQuery);
